import styled from "styled-components";
import { Link as LinkReactDom } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";
import colors from "../../styles/colors";

export const Nav = styled.nav`
  height: 75px;

  /* background: ${colors.azulClaro}; */
  background: #fff;
  /* border-bottom: 1px solid ${colors.azulEscuro}; */

  margin-top: -80px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1rem;
  position: sticky;
  top: 0;

  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;

  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;
export const NavbarLogo = styled(LinkReactDom)`
  color: #fff;

  cursor: pointer;
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-weight: bold;
  text-decoration: none;

  @media screen and (max-width: 786px) {
    img {
      width: 40%;
    }
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 786px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    transform: translate(-100%, 60%);

    font-size: 1.8rem;
    cursor: pointer;
  }
`;
export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavLink = styled(LinkScroll)`
  color: #535353;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid ${colors.azulEscuro};
  }
  /* font-family: "Montserrat", sans-serif; */
`;

export const NavItem = styled.li`
  height: 80px;
`;
