import React from "react";

import logo from "../../assets/cooperando.png";
import {
  Container,
  Wrap,
  Logo,
  Endereco,
  Links,
  FooterLink,
  Direitos,
} from "./styles";

function Footer() {
  return (
    <Container>
      <Wrap>
        {/* <Logo> */}
        <Logo src={logo} alt="Cooperando" />
        {/* </Logo> */}
        <Links>
          <FooterLink to="somos">Quem somos</FooterLink>
          <FooterLink to="fazemos">O que fazemos</FooterLink>
          <FooterLink to="resolvemos">O que resolvemos</FooterLink>

          <a href="/files/politica_de_dados.pdf" target="_blank">
            Política de dados
          </a>
        </Links>
        <Endereco>
          <p>Av. Presidente Vargas, 541</p>
          <p>CEPV, Sala 308</p>
          <p>sac@cooperandoapp.com.br</p>
        </Endereco>
      </Wrap>
      <Direitos>
        Cooperando Inteligência + Interação, 2021, todos os direitos reservados.
      </Direitos>
    </Container>
  );
}

export default Footer;
