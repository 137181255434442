import React from "react";

import image from "../../assets/desktop/2SiteCooperando_Desktop_VetorClienteFala.png";

import icone from "../../assets/desktop/2SiteCooperando_Desktop_Símbolo.png";

import {
  Container,
  Row,
  Column1,
  Title,
  Descripton,
  Column2,
  Img,
  Infinito,
  Degrade,
  TitleVermelho,
} from "./styles";

const InfoSection = () => {
  return (
    <div id="fazemos">
      <Degrade />
      <Container>
        <Row>
          <Column1>
            <Title>O que o cliente fala da sua empresa</Title>

            <Descripton>
              Para oferecer uma experiência de verdade, a{" "}
              <strong>
                empresa precisa ouvir o que os seus clientes pensam{" "}
              </strong>
              e falam sobre ela.
              <br />
              <br />
              <strong>E isso vai além de coletar dados.</strong>
              <br />
              <br />A Cooperando ajuda a sua empresa a conhecer melhor o mercado
              e o seu público.{" "}
              <strong>
                Identificando oportunidades, reduzindo riscos e direcionando de
                forma inteligente as decisões.
              </strong>
              <br />
              Oferecendo soluções integradas para que{" "}
              <strong>
                você endenda experiências e processos de compra
              </strong>{" "}
              do seu cliente e posssa{" "}
              <strong>identificar oportunidades de crescimento</strong> do seu
              negócio.
            </Descripton>
          </Column1>
          <Column2>
            <Img src={image} alt="Cooperando" />
          </Column2>
        </Row>
        <Infinito src={icone} alt="Cooperando" />

        <Column1>
          <Title>Transformando dados em inteligência!</Title>
        </Column1>

        <Row>
          <Column1>
            <TitleVermelho>O que o cliente fala da sua empresa</TitleVermelho>
          </Column1>
          <Column2>
            <Descripton>
              Acreditamos que
              <strong>
                {" "}
                a pesquisa e análise de dados são essenciais para a gestão das
                empresas{" "}
              </strong>
              no mundo atual onde as mudanças são constantes.
              <br />
              Interagimos com empresas para{" "}
              <strong>indentificar oportunidades. </strong>
              Buscamos dados e transformamos em{" "}
              <strong>informações para inteligência de mercado.</strong>
              Tudo com foco na realidade de cada empresa.
            </Descripton>
          </Column2>
        </Row>
      </Container>
    </div>
  );
};

export default InfoSection;
