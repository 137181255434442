import React from "react";

import { Container, Background, Content, Title, SubTitle } from "./styles";

function HomeSection() {
  return (
    <Container>
      <Background />
      <Content>
        <Title>Você sabe o que o seu cliente tem falado da sua empresa?</Title>
        <SubTitle>A gente ajuda você a descobrir!</SubTitle>
      </Content>
    </Container>
  );
}

export default HomeSection;
