import React, { useCallback, useRef, useState } from "react";
import HomeSection from "../../components/HomeSection";
import NavBar from "../../components/NavBar";
import Sidebar from "../../components/Sidebar";
import InfoSection from "../../components/InfoSection";
import QuemSomos from "../../components/QuemSomos";
import Nps from "../../components/Nps";
import Footer from "../../components/Footer";

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const quemSomosSection = useRef(null);
  const fazemosSection = useRef(null);
  const resolvemosSection = useRef(null);

  const scrollToQuemSomos = useCallback(
    () => scrollToRef(quemSomosSection),
    []
  );
  const scrollToFazemos = useCallback(() => scrollToRef(fazemosSection), []);
  const scrollToResolvemos = useCallback(
    () => scrollToRef(resolvemosSection),
    []
  );

  const handleOpenSideBar = () => {
    setIsOpen(!isOpen);
  };

  const scrollToRef = (ref) => {
    console.log(ref);
    try {
      window.scrollTo(0, ref.current.offsetTop);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Sidebar
        handleOpenSideBar={handleOpenSideBar}
        isOpen={isOpen}
        quemSomosSection={quemSomosSection}
        resolvemosSection={resolvemosSection}
        fazemosSection={fazemosSection}
        scrollToResolvemos={scrollToResolvemos}
        scrollToQuemSomos={scrollToQuemSomos}
        scrollToFazemos={scrollToFazemos}
      />
      <NavBar
        handleOpenSideBar={handleOpenSideBar}
        scrollToResolvemos={scrollToResolvemos}
        scrollToQuemSomos={scrollToQuemSomos}
        scrollToFazemos={scrollToFazemos}
      />
      <HomeSection />
      <InfoSection ref={fazemosSection} />
      <QuemSomos ref={quemSomosSection} />
      <Nps ref={resolvemosSection} />
      <Footer />

      <a
        href="https://api.whatsapp.com/send?phone=5554981141000&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Cooperando"
        class="float"
        target="_blank"
        rel="noreferrer"
      >
        <i class="fa fa-whatsapp my-float"></i>
      </a>
    </>
  );
}

export default Home;
