import styled from "styled-components";
import colors from "../../styles/colors";

import background from "../../assets/desktop/2SiteCooperando_Desktop_Onda.png";
import backgroundoMobile from "../../assets/mobile/2SiteCooperando_Mobile_Onda.png";

import degrade from "../../assets/desktop/degrade.png";

export const Container = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;

  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;

  background-image: url(${background});

  @media screen and (max-width: 768px) {
    background-size: 100% 60%;

    background-image: url(${backgroundoMobile});
  }

  z-index: 1;
`;

export const Row = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);

  align-items: center;
  justify-content: center;

  width: 80%;

  padding: 24px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
`;

export const Column1 = styled.div`
  max-width: 540px;
  margin-bottom: 15px;
  padding: 15px;

  h1 {
  }
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 15px;
`;

export const Title = styled.h1`
  color: ${colors.azulEscuro};

  text-align: left;

  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 36px;
  }
`;
export const TitleVermelho = styled.h1`
  color: ${colors.vermelho};

  text-align: right;
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 36px;
  }
`;

export const Descripton = styled.p`
  font-size: 18px;
  text-align: justify;

  color: ${colors.cinza};

  margin-top: 20px;
  strong {
    color: #5f6060;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;

  padding-left: 0;
`;

export const Infinito = styled.img`
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
  margin: 0 0 10px 0;

  padding-left: 0;
`;

export const Degrade = styled.div`
  width: 100%;
  height: 40px;

  overflow: hidden;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  background-image: url(${degrade});
`;
