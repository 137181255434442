import React from "react";

import { Container, CloseIcon, Icon, Wrapper, Menu, Link } from "./styles";

function Sidebar({
  isOpen,
  handleOpenSideBar,

  quemSomosSection,
  resolvemosSection,
  fazemosSection,
  scrollToResolvemos,
  scrollToQuemSomos,
  scrollToFazemos,
}) {
  return (
    <Container isOpen={isOpen} onClick={handleOpenSideBar}>
      <Icon>
        <CloseIcon />
      </Icon>

      <Wrapper>
        <Menu>
          <Link
            to="somos"
            onClick={() => {
              handleOpenSideBar();
              scrollToQuemSomos(quemSomosSection);
            }}
          >
            Quem somos
          </Link>
          <Link
            to="fazemos"
            onClick={() => {
              handleOpenSideBar();
              scrollToFazemos(fazemosSection);
            }}
          >
            O que fazemos
          </Link>
          <Link
            to="resolvemos"
            onClick={() => {
              handleOpenSideBar();
              scrollToResolvemos(resolvemosSection);
            }}
          >
            O que resolvemos
          </Link>
          {/* <Link
            to="contatos"
            onClick={() => {
              handleOpenSideBar();
            }}
          >
            Contatos
          </Link> */}
        </Menu>
      </Wrapper>
    </Container>
  );
}

export default Sidebar;
