import styled from "styled-components";
import colors from "../../styles/colors";
import { Link as LinkScroll } from "react-scroll";

export const Container = styled.footer`
  background-color: ${colors.azulEscuro};

  display: flex;
  width: 100%;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  padding: 20px;
`;
export const Wrap = styled.div`
  display: flex;
  flex: 1;

  /* width: 100%; */

  flex-direction: row;

  align-items: center;
  justify-content: center;

  @media screen and (max-width: 786px) {
    flex-direction: column;
  }
`;

export const Logo = styled.img`
  width: 300px;
`;
export const Endereco = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  /* align-items: center; */
  justify-content: center;

  @media screen and (max-width: 786px) {
    align-items: center;
  }

  p {
    text-align: left;
    font-size: 16px;

    color: #fff;

    font-family: "Montserrat Thin", sans-serif;
    /* font-family: "Montserrat", sans-serif; */
  }

  margin: 40px;
`;

export const Links = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 40px;

  a {
    text-decoration: none;
    color: #fff;

    font-family: "Montserrat Thin", sans-serif;
    font-size: 16px;

    &:hover {
      color: ${colors.azulClaro};
    }
  }
`;

export const FooterLink = styled(LinkScroll)`
  text-decoration: none;
  color: #fff;
  font-family: "Montserrat Thin", sans-serif;
  font-size: 16px;

  &:hover {
    color: ${colors.azulClaro};
  }
`;

export const Direitos = styled.p`
  text-align: left;
  font-size: 16px;

  color: #fff;
  font-family: "Montserrat Thin", sans-serif;

  margin-bottom: 10px;
`;
