import styled from "styled-components";
import colors from "../../styles/colors";

import degrade from "../../assets/desktop/degrade.png";

export const Container = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;

  align-items: center;

  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;

  background: ${colors.azulClaro};
`;
export const Degrade = styled.div`
  width: 100%;
  height: 25px;

  display: flex;

  align-items: center;
  justify-content: center;

  overflow: hidden;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${degrade});

  img {
    overflow: hidden;
    position: absolute;

    top: 0;
    right: 20px;

    @media screen and (max-width: 768px) {
      height: 120px;
      right: 0;
      top: 10px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 50%;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    width: 70%;
  }

  img {
    width: 100%;
  }

  p {
    font-size: 18px;

    color: ${colors.cinza};
    text-align: left;

    margin-top: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

export const Detratores = styled.div`
  max-width: 100%;

  h2 {
    color: ${colors.azulEscuro};
    font-size: 26px;

    text-align: left;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  p {
    font-size: 18px;
  }
`;

export const Title = styled.h1`
  font-size: 26px;
  text-align: left;

  color: ${colors.vermelho};
  @media screen and (max-width: 768px) {
    font-size: 16px;
    text-align: left;

    width: 80%;
  }
`;

export const SubTitle = styled.h1`
  font-size: 26px;
  text-align: left;

  width: 100%;

  color: ${colors.vermelho};

  @media screen and (max-width: 768px) {
    font-size: 16px;
    text-align: left;
  }
`;

export const TuaOpiniao = styled.div`
  background-color: ${colors.vermelho};

  display: flex;
  flex: 1;

  align-items: center;
  justify-content: center;

  padding-top: 20px;
`;

export const DivFlex = styled.div`
  display: flex;

  width: 80%;

  flex-direction: row;

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }

  align-items: center;
  justify-content: center;
`;

export const WhatsAppImage = styled.div`
  display: flex;
  flex: 1;

  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;

export const Descricao = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;

  align-items: center;
  justify-content: center;

  padding: 10px;
  h1 {
    font-size: 26px;
    text-align: left;

    color: #fff;

    width: 60%;

    @media screen and (max-width: 768px) {
      width: 100%;
      font-size: 16px;
      text-align: center;
    }
  }

  p {
    font-size: 16px;
    /* font-family: "Montserrat Regular", sans-serif; */

    color: #fff;
    text-align: left;

    width: 60%;

    margin-top: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      font-size: 13px;

      width: 100%;
      text-align: center;
    }
  }
`;
