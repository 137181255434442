import styled from "styled-components";

import banner from "../../assets/desktop/banner2.png";
import colors from "../../styles/colors";

export const Container = styled.div`
  display: flex;

  flex: 1;

  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  overflow: hidden;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  background-image: url(${banner});
`;

export const Content = styled.div`
  display: flex;

  flex: 1;
  flex-direction: column;
  justify-content: center;

  max-width: 1200px;

  width: 100%;

  position: absolute;
  padding: 0 24px;

  z-index: 3;
`;

export const Title = styled.h1`
  font-size: 48px;

  max-width: 600px;
  background: linear-gradient(
    to right,
    ${colors.azul},
    ${colors.vermelho},
    ${colors.azulEscuro},
    ${colors.vermelho}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-align: left;

  margin-left: 10px;

  @media screen and (max-width: 768px) {
    font-size: 36px;
  }

  @media screen and (max-width: 480px) {
    font-size: 28px;
  }
`;

export const SubTitle = styled.p`
  font-size: 38px;
  font-family: "Hoop Regular", sans-serif;

  color: ${colors.cinza};

  max-width: 250px;
  text-align: left;

  margin-left: 10px;

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
`;
