import styled, { css } from "styled-components";

import degrade from "../../assets/desktop/degrade.png";
import colors from "../../styles/colors";

// import background from "../../assets/desktop/2SiteCooperando_Desktop_BackgroundMindMap.png";
// import backgroundMobile from "../../assets/mobile/2SiteCooperando_Mobile_BackgoundMindMap.png";

export const Container = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;

  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
`;

export const Degrade = styled.div`
  width: 100%;
  height: 60px;

  display: flex;

  align-items: center;
  justify-content: center;

  overflow: hidden;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  background-image: url(${degrade});

  h1 {
    color: #fff;

    text-align: left;

    @media screen and (max-width: 768px) {
      text-align: center;
      font-size: 24px;
    }
  }
`;

export const Row = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);

  width: 80%;
  height: auto;

  min-height: 300px;

  margin: 50px;

  z-index: 1;

  @media screen and (max-width: 768px) {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    padding-left: 20px;
    padding-right: 20px;

    grid-gap: 10px;
  }

  div {
    display: flex;
    flex: 1;

    padding: 20px;
    @media screen and (max-width: 768px) {
      padding: 0px;
    }

    a {
      text-decoration: none;
      h1 {
        color: ${colors.vermelho};
        font-size: 18px;

        text-align: center;

        margin-top: 10px;
      }
    }

    align-items: center;
    justify-content: center;

    img {
      width: 180px;
      height: 180px;

      border-radius: 50%;

      @media screen and (max-width: 768px) {
        width: 100px;
        height: 100px;
      }
    }
  }
`;

export const Description = styled.p`
  /* font-family: "Montserrat", sans-serif; */

  color: ${colors.cinza};

  text-align: right;
  margin-right: 20px;

  font-size: 18px;

  ${(props) =>
    props.left &&
    css`
      text-align: left;
      margin-left: 20px;

      @media screen and (max-width: 768px) {
        text-align: center;
        font-size: 14px;
      }
    `}

  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 14px;
  }
`;

// export const Background = styled.div`
//   position: absolute;

//   width: 100%;
//   height: 100%;

//   overflow: visible;

//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   background-image: url(${background});

//   @media screen and (max-width: 768px) {
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//     background-image: url(${backgroundMobile});
//   }

//   z-index: -1;
// `;

export const Content = styled.div`
  display: flex;

  flex: 1;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  /* margin-top: 60px; */

  background: ${colors.azul};
`;

export const ContentDiv = styled.div`
  margin-top: 20px;

  display: flex;
  flex: 1;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }

  h1 {
    color: ${colors.azulEscuro};
    font-size: 26px;

    text-align: center;

    margin-top: 10px;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  img {
    width: 100%;
  }
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 26px;

  max-width: 50%;

  text-align: center;
  /* color: ${colors.azulEscuro}; */

  margin-top: 50px;
  margin-bottom: 10px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    max-width: 80%;
  }
`;

export const SubTitle = styled.p`
  font-size: 16px;
  /* font-family: "Montserrat Regular", sans-serif; */

  max-width: 30%;
  color: #fff;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    max-width: 90%;
    margin-bottom: 20px;
  }
`;
