import React from "react";

import clientes from "../../assets/desktop/2SiteCooperando_Desktop_VetorDetratores.png";
import nps from "../../assets/desktop/2SiteCooperando_Desktop_NPS.png";
import whats from "../../assets/desktop/2SiteCooperando_Desktop_Whats.png";

import {
  Container,
  Degrade,
  Content,
  Title,
  Detratores,
  TuaOpiniao,
  SubTitle,
  DivFlex,
  Descricao,
  WhatsAppImage,
} from "./styles";

function Nps() {
  return (
    <Container id="resolvemos">
      <Degrade>
        <img src={nps} alt="NPS" />
      </Degrade>

      <Content>
        <Title>Quer saber como o cliente percebe a sua empresa? </Title>
        <Title>Pergunte a ele!</Title>

        <p>
          Utilizamos a metodologia <strong>Net Promoter Score</strong>, para
          medir o grau de satisfação e lealdade dos seus clientes, e o quanto
          ele recomendaria o seu serviço para amigos ou familiares
        </p>

        <SubTitle>
          Com perguntas simples e customizadas você pode identificar os seus
        </SubTitle>

        <img src={clientes} alt="Clientes" />

        <Detratores>
          <h2>
            Quando um detrator aparece, sua empresa pode ter em mãos uma grande
            oportunidade
          </h2>
          <p>
            Com <strong>inteligência artificial</strong> ajudamos você a{" "}
            <strong>
              identificar os melhores caminhos para solucionar os problemas
            </strong>{" "}
            dos seus clientes. <br />
            <strong>Transforme um detrator em um promotor</strong>
          </p>
        </Detratores>
      </Content>

      <TuaOpiniao>
        <DivFlex>
          <Descricao>
            <h1>Ajudamos você a ouvir o seu cliente!</h1>
            <h1>Por isso coletamos dados de forma simples e objetiva</h1>
            <p>
              Vivemos na <strong>era da comunicação digital,</strong> então por
              que com pesquisas seria diferente? Por isso, nossos métodos de
              aplicação das pesqusias são adaptáveis aos objetivos que desejam
              ser alcançados.
              <br /> Para ouvir o mercado,{" "}
              <strong>usamos diversas técnicas de coleta de dados,</strong>{" "}
              usando dados da própria empresa, fazendo entrevistas presenciais,
              assim como via ligação telefônica/vídeo conferência.
            </p>
          </Descricao>

          <WhatsAppImage>
            <img src={whats} alt="WhatsApp" />
          </WhatsAppImage>
        </DivFlex>
      </TuaOpiniao>
    </Container>
  );
}

export default Nps;
