import React from "react";

import { FaBars } from "react-icons/fa";

import logo from "../../assets/logo.svg";
import {
  Nav,
  NavbarContainer,
  NavbarLogo,
  MobileIcon,
  NavMenu,
  NavLink,
  NavItem,
} from "./styles";

function NavBar({
  handleOpenSideBar,
  scrollToResolvemos,
  scrollToQuemSomos,
  scrollToFazemos,
}) {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavbarLogo to="/">
            <img src={logo} alt="Cooperando" />
          </NavbarLogo>

          <MobileIcon onClick={handleOpenSideBar}>
            <FaBars color="#535353" />
          </MobileIcon>

          <NavMenu>
            <NavItem>
              <NavLink to="fazemos" onClick={scrollToFazemos}>
                O que fazemos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="somos" onClick={scrollToQuemSomos}>
                Quem somos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="resolvemos" onClick={scrollToResolvemos}>
                O que resolvemos{" "}
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink to="contatos">Contatos </NavLink>
            </NavItem> */}
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default NavBar;
