/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import kenny from "../../assets/kenny.jpg";
import vitor from "../../assets/vitor.jpeg";

import image from "../../assets/desktop/2SiteCooperando_Desktop_VetorMindMap.png";

import {
  Row,
  Container,
  Degrade,
  Title,
  Description,
  Content,
  SubTitle,
  // Background,
  ContentDiv,
} from "./styles";

function QuemSomos() {
  return (
    <Container id="somos">
      <Degrade>
        <h1> Quem faz a Cooperando</h1>
      </Degrade>

      <Row>
        <div>
          <Description>
            Doutor em Administração com experiência na área de Marketing e
            Comportamento do Consumidor. Pesquisador e autor de artigos
            publicados em periódicos internacionais como Journal of Service
            Research, European Journal of Marketing, The Journal of Services
            Marketing, Journal of Retailing and Consumer Services, International
            Journal of Bank Marketing e Journal of Product & Brand Management.
          </Description>
          <a
            href="https://www.linkedin.com/in/kenny-basso-9016ab187/"
            target="_blank"
          >
            <img src={kenny} alt="Kenny Basso" />
            <h1>Kenny Basso</h1>
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/vitor-dalla-corte-b68b08121/"
            target="_blank"
          >
            <img src={vitor} alt="Vitor Dalla Corte" />
            <h1>Vitor Dalla Corte</h1>
          </a>
          <Description left={true}>
            Doutor em Agronegócios com período internacional na Universidade de
            Wisconsin, EUA. Professor e Pesquisador do Mestrado em Administração
            da Faculdade Meridional - IMED/RS, com artigos publicados nas áreas
            e Economia Aplicada, Agronegócios e em temas relacionados à gestão e
            estratégia empresarial.
          </Description>
        </div>
      </Row>

      <Content>
        {/* <Background /> */}
        <Title>
          Mais que números, entregamos uma conexão da empresa com o mercado
        </Title>
        <SubTitle>
          A Cooperando{" "}
          <strong>
            personaliza a solução de acordo com o negócio/empresa.
          </strong>{" "}
          Assim, os dados são otimizados e transformados em informações
          realmente pertinentes para o sucesso da empresa
        </SubTitle>

        <ContentDiv>
          <img src={image} alt="Experiencia" />

          <h1>Dando voz ao seu cliente, você conecta sua marca ao mercado</h1>
        </ContentDiv>
      </Content>
    </Container>
  );
}

export default QuemSomos;
